<template lang="">
  <b-card>
    <b-row>
      <b-col cols="12">
        <b-row>
          <b-col cols="4">
            <h3 class="font-weight-bolder">
              Filter
            </h3>
          </b-col>
          <b-col cols="8" class="mb-2 d-flex flex-row-reverse">
            <div v-if="loading">
              <b-spinner small />
              Loading...
            </div>
            <div v-else>
              <b-button
                class="mr-1"
                size="sm"
                variant="warning"
                @click="exportExcel"
              >
                Export
              </b-button>
            </div>
          </b-col>
        </b-row>
        <br>
        
        <form-v-select
          ref="region"
          dataurl="region"
          v-model='region'
          label='Region'
          placeholder='Semua Region'
          :item_text="'name'"
          @input="changeBranch"
        />

        <form-v-select
          ref="area"
          v-model='area'
          label='Area'
          placeholder='Semua Area'
          :item_text="'name'"
          @input="changeSelectArea"
        />

        <form-v-select
          ref="ulp"
          v-model='ulp'
          label='Ulp'
          placeholder='Semua Ulp'
          :item_text="'name'"
          @input="changeSelectUlp"
        />

        <form-v-select
          ref="kantorjaga"
          v-model='kantorjaga'
          label='Kantor Jaga'
          placeholder='Semua Kantor jaga'
          :item_text="'name'"
        />
        
        <form-v-select
          ref="project"
          v-model="project"
          dataurl="project"
          label="Project"
          placeholder="Semua Project"
          :item_text="'name'"
        />

        <b-form-group
            label-cols-md="4"
            label="Periode Type">

            <b-form-select
              v-model="period_type"
              @change="changePeriod"
              :options="[
                { value: 'annual', text: 'Annual' },
                { value: 'monthly', text: 'Monthly' },
                { value: 'weekly', text: 'Weekly' },
                { value: 'daily', text: 'Daily' },
              ]"
            />
        </b-form-group>

        <b-form-group
          label-cols-md="4"
          label="Periode"
        >
          <b-input-group>
            <cleave
              v-if="period_type == 'annual'"
              v-model="year"
              class="form-control"
              :raw="true"
              :options="{
                numeral: true,
                numeralThousandsGroupStyle:
                'none',
              }"
              placeholder="Tahun"
            />
            <b-form-select
              v-else-if="period_type == 'monthly'"
              v-model="month"
              :options="[
                { value: '1', text: 'January' },
                { value: '2', text: 'Febuary' },
                { value: '3', text: 'Maret' },
                { value: '4', text: 'April' },
                { value: '5', text: 'Mei' },
                { value: '6', text: 'Juni' },
                { value: '7', text: 'Juli' },
                { value: '8', text: 'Agustus' },
                { value: '9', text: 'September' },
                { value: '10', text: 'Oktober' },
                { value: '11', text: 'November' },
                { value: '12', text: 'Desember' },
              ]"
            />
            <flat-pickr
              v-else-if="period_type == 'daily'|| period_type == 'weekly'"
              v-model="date_start"
              @input="changePeriod"
              class="form-control"
              placeholder="Tanggal Awal"
            />
            <b-input-group-append is-text>
           {{period_type=='annual'?'Year':period_type=='monthly'?'Month':'To'}}
            </b-input-group-append>
            <cleave
              v-if="period_type == 'monthly'"
              v-model="year"
              class="form-control"
              :raw="true"
              :options="{
                numeral: true,
                numeralThousandsGroupStyle: 'none',
              }"
              placeholder="Tahun"
            />
            <flat-pickr
              v-if="period_type == 'daily' || period_type == 'weekly'"
              v-model="date_end"
              class="form-control"
              @input="changeDateEnd"
              placeholder="Tanggal Akhir"
            />
            <b-input-group-append is-text v-if="period_type == 'monthly'">
              Year
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label-cols-md="4"
          label=""
        >
          <b-button
            class="mr-1"
            size="sm"
            variant="primary"
            type="button"
            @click="show"
          >Show</b-button>
          <b-button
            class="mr-1"
            size="sm"
            variant="outline-secondary"
            type="button"
            @click="reset"
          >Reset</b-button>
        </b-form-group>
      </b-col>
      <b-col cols='12'><hr></b-col>
      <b-col cols="7">
        <h3 class="font-weight-bolder">
          Biaya BBM
        </h3>
        <br>
        
        <vue-apex-charts 
        ref="realtimeChartBBM"
        type="line" 
        height="250" 
        :options="chartOptionsComputedLine" 
        :series="costSeries" />
      </b-col>
      <b-col cols="5">
        <h3 class="font-weight-bolder">
          Kategori BBM
        </h3>
        <br>
        <vue-apex-charts 
          ref="realtimePieBBM" 
          type="pie" 
          height="350" 
          :options="chartOptionsComputed" 
          :series="categorySeries" />
      </b-col>
      <b-col cols='12'><hr></b-col>
      <b-col cols="6">
        <h3 class="font-weight-bolder">
          Jumlah (Liter)
        </h3>
        <br>
        
        <vue-apex-charts 
        ref="realtimeChartBBMQty"
        type="bar" 
        height="250" 
        :options="chartOptionsComputedLine" 
        :series="literSeries" />
      </b-col>
      <b-col cols="6">
        <h3 class="font-weight-bolder">
          Harga BBM per Liter
        </h3>
        <br>
        <vue-apex-charts 
        ref="realtimeChartBBMLiter"
        type="bar" 
        height="250" 
        :options="chartOptionsComputedLine" 
        :series="costLiterSeries" />
      </b-col>
      <b-col cols='12'><hr></b-col>
      <b-col cols="6">
        <h3 class="font-weight-bolder">
          Laporan Bulan Ini
        </h3>
        <br>
        
        <base-info
        :geturl="dataurl"
        :fields="fields"
        :isedit="false"
        :isdelete="false" />
      </b-col>
      <b-col cols="6">
        <h3 class="font-weight-bolder">
          Laporan Hari Ini
        </h3>
        <br>

        <base-info
          :geturl="dataurl"
          :fields="fieldsToday"
          :isedit="false"
          :isdelete="false" />
      </b-col>
      <b-col cols='12'><hr></b-col>
      <b-col cols="12">
        <h3 class="font-weight-bolder">
          Daftar Penggunaan BBM
        </h3>
        <br>
        
        <b-overlay
          :show="loading"
          variant="light"
          :opacity="0.5"
          rounded="sm"
        >

          <b-table
            ref="basetable"
            striped
            responsive
            :items="item"
            :fields="fieldsTable">

            <template #cell(liter)='data'>
              <span>Rp {{data.item.liter|numFormat}}</span>
            </template>
            <template #cell(count)='data'>
              <span>{{data.item.count|numFormat}}</span>
            </template>
          </b-table>
        </b-overlay>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { BCard,BButton,BFormGroup,BFormSelect,BRow,BCol,BTable,BOverlay,BInputGroup, BInputGroupAppend, BSpinner } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { pieChartOptions } from '../../vehicle/chartOptions'
import { lineChartOptions } from '../../vehicle/chartOptions'
import BaseInfo from '@/views/base/BaseInfo.vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'vue-cleave-component'
import moment from 'moment'
import _ from 'lodash'

export default {
  components: {
    BTable,
    BOverlay,
    BCard,
    BButton,
    BFormGroup,
    VueApexCharts,
    BFormSelect,
    BRow,
    BCol,
    BaseInfo,
    BSpinner,
    FormVSelect,
    BInputGroup,
    BInputGroupAppend,
    flatPickr,
    Cleave
  },
  data() {
    return {
      dataurl:"/finance/fuel",
      loading:false,
      fields:[
        {key:'month.used', label:'Transaksi', type:'money'},
        {key:'month.liter', label:'Liter', type:'number'},
        {key:'month.count', label:'Jumlah', type:'number'}
      ],
      fieldsToday:[
        {key:'today.used', label:'Transaksi', type:'money'},
        {key:'today.liter', label:'Liter', type:'number'},
        {key:'today.count', label:'Jumlah', type:'number'}
      ],
      fieldsTable:[
        {key:'branch_name', label:'Regional'},
        {key:'fuel_name', label:'Jenis BBM'},
        {key:'count', label:'Jumlah Liter'},
        {key:'liter', label:'Biaya BBM'},
      ],
      costSeries:[],
      categorySeries:[],
      literSeries:[],
      costLiterSeries:[],
      item:[],
      dataMonth:null,
      dataDay:null,
      region:null,
      area:null,
      ulp:null,
      kantorjaga:null,
      project: null,
      isMonth:true,
      period_type:'annual',
      date_end:null,
      date_start:null,
      month:'1',
      year:moment().format("YYYY"),
      filter:[]
    }
  },
  computed: {
    chartOptionsComputed() {
      const options = JSON.parse(JSON.stringify(pieChartOptions))
      return options
    },
    chartOptionsComputedLine() {
      const options = JSON.parse(JSON.stringify(lineChartOptions))
      return options
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async exportExcel(){
      var params = {...this.filter}
      params["sort[created_at]"] = "desc"
      this.loading = true
      this.$http.get('finance/fuel/export', {params:params}).then(res => {
        window.open(res.data.filename)
        this.loading = false
      })
    },
    fetchDataArea(parent_id) {
      var params = {}
      if (parent_id) {
        params['filter[parent_id]'] = parent_id
      }
      this.$http.get('service-area', { params: params }).then(res => {
        this.optionArea = res.data.data
        this.$refs.area.refreshData(this.optionArea)
      })
    },
    fetchDataUlp(parent_id) {
      var params = {}
      if (parent_id) {
        params['filter[parent_id]'] = parent_id
      }
      this.$http.get('service-center', { params: params }).then(res => {
        this.optionULP = res.data.data
        this.$refs.ulp.refreshData(this.optionULP)
      })
    },
    fetchDataKantorjaga(parent_id) {
      var params = {}
      if (parent_id) {
        params['filter[parent_id]'] = parent_id
      }
      this.$http.get('service-guard', { params: params }).then(res => {
        this.optionKantorjaga = res.data.data
        this.$refs.kantorjaga.refreshData(this.optionKantorjaga)
      })
    },
    changeBranch(data) {
      if (data != null) {
        this.fetchDataArea(data.id)
      } else {
        this.$refs.area.refreshData([])
        this.$refs.ulp.refreshData([])
        this.$refs.kantorjaga.refreshData([])
      }
    },
    changeSelectArea(data) {
      if (data != null) {
        this.fetchDataUlp(data.id)
      } else {
        this.$refs.ulp.refreshData([])
        this.$refs.kantorjaga.refreshData([])
      }
    },
    changeSelectUlp(data) {
      if (data != null) this.fetchDataKantorjaga(data.id)
      else this.$refs.kantorjaga.refreshData([])
    },
    changePeriod(){
      if (this.period_type == "weekly")
      {
        // console.log(moment(this.date_start).add(7,'days'))
        this.date_end = moment(this.date_start).add(7,'days').format("YYYY-MM-DD")
      }
    },
    changeDateEnd(){

      if (this.period_type == "weekly")
      {
        // console.log(moment(this.date_end).subtract(7,'days'))
        this.date_start = moment(this.date_end).subtract(7,'days').format("YYYY-MM-DD")
      }
    },
    show(){
      var branch_id = null
      if(this.kantorjaga)
        branch_id = this.kantorjaga.id
      else if(this.ulp)
        branch_id = this.ulp.id
      else if(this.area)
        branch_id = this.area.id
      else
        branch_id = this.region?this.region.id:null

      this.filter['filter[vehicles.branch_id]'] = branch_id

      if (this.project)
        this.filter['project_id'] = this.project.id
        
      this.categorySeries = []
      this.fetchData()
    },
    reset(){
      this.categorySeries = []
      this.filter = []
      this.region = null
      this.area = null
      this.ulp = null
      this.kantorjaga = null
      this.period_type == 'annual'
      this.fetchData()
    },
    fetchData(){
      // this.filter['filter[periode_type]'] = this.period_type
      if (this.period_type == 'annual'){
        this.date_start = moment().set('date', 1).set('month',0).set('year',this.year).format("YYYY-MM-DD")
        this.date_end = moment().set('date', 31).set('month',11).set('year',this.year).format("YYYY-MM-DD")
      }else if (this.period_type == 'monthly'){
        this.date_start = moment().set('date', 1).set('month', this.month - 1).set('year',this.year).format("YYYY-MM-DD")
        this.date_end = moment().set('month', this.month - 1).set('year',this.year).endOf('month').format("YYYY-MM-DD")
      }
      
      this.filter.periode_type = this.period_type
      this.filter['between[date]'] = this.date_start + "," + this.date_end
      
      var params ={...this.filter}
      this.$http.get(this.dataurl, {params:params}).then(res => {

        this.setDataCost(res.data.cost)
        this.setDataCategory(res.data.category)
        this.setDataLiter(res.data.liter)
        this.setDataCostLiter(res.data.price)
        this.item = res.data.table
        this.dataMonth = res.data.month
        this.dataDay = res.data.today
      })
    },
    setDataCategory(result){
      let label = []
      for(let i=0; i<result.length; i++){
        label.push(result[i].fuel.name)
        this.categorySeries.push(parseFloat(result[i].liter))
      }
      this.$refs.realtimePieBBM.updateOptions({ labels: label })
    },
    setDataCost(result){
      let label = []
      this.costSeries = []
      if(result.length > 0){
        for(let i=0; i<result[0].length; i++){
          this.label.push((i+1))
        }
      }
      for(let i=0; i<result.length; i++){
       this.costSeries.push({name: result[i].fuel.name, data: result[i].data  })
      }
      
      this.$refs.realtimeChartBBM.updateOptions({ xaxis: {type:'string',categories: label} });
    },
    setDataLiter(result){
      let label = null
      this.literSeries = []
      for (var key in result) {
        if (label == null)
          label = _.map(result[key],function(value){
            if (value.key.toString().length <= 2)
              return moment().set('month',value.key-1).format("MMM")
            return moment(value.key).format("DD")
          })
          this.literSeries.push({name: key, data: _.map(result[key],'sum') })
      }
      this.$refs.realtimeChartBBMQty.updateOptions({ xaxis: {type:'string',categories: label} });
    },
    setDataCostLiter(result){
      let label = null
      this.costLiterSeries = []
      
      for (var key in result) {
        if (label == null)
          label = _.map(result[key],function(value){
            if (value.key.toString().length <= 2)
              return moment().set('month',value.key-1).format("MMM")
            return moment(value.key).format("DD")
          })
          this.costLiterSeries.push({name: key, data: _.map(result[key],'sum') })
      }
      this.$refs.realtimeChartBBMLiter.updateOptions({ xaxis: {type:'string',categories: label} });
    }
  }
}
</script>
<!-- style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style -->